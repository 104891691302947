'use client';

import { Token } from '@baseswapfi/sdk-core';
import { ReactNode, createContext, useCallback, useContext, useMemo } from 'react';
import { useChainId } from 'wagmi';
import { GqlToken, useGetTokensQuery } from '~/apollo/generated/graphql-codegen-generated';
import { TokenAmountHumanReadable } from '~/lib/services/token/token-types';
import { useUserImportedTokens } from '~/lib/user/useUserImportedTokens';
import { numberFormatUSDValue } from '~/lib/util/number-formats';
import { BaseNetworkConfig } from '../config/base-config';
import { isSupportedChain } from '../util/chains';
import useNetworkConfig from './useNetworkConfig';
export const TokensContext = createContext<ReturnType<typeof _useGetTokens> | null>(null);
export interface TokenWithImportedFlag extends GqlToken {
  imported?: boolean;
}
export function _useGetTokens() {
  const networkConfig = useNetworkConfig();
  const chainId = useChainId() || BaseNetworkConfig.chainId;
  const skip = !isSupportedChain(chainId);
  const {
    data: tokensResponse,
    error
  } = useGetTokensQuery({
    fetchPolicy: 'cache-first',
    variables: {
      chainId
    }
  });

  // const { data: allTokensResponse } = useGetAllTokensForChainQuery({
  //   fetchPolicy: 'cache-first',
  //   variables: {
  //     chainId,
  //   },
  //   skip,
  // });

  // console.log(allTokensResponse);

  if (error) console.log(error);
  const {
    userImportedTokens
  } = useUserImportedTokens();
  const tokens: TokenWithImportedFlag[] = useMemo(() => [...(tokensResponse?.tokens || []), ...userImportedTokens].map(token => ({
    ...token,
    address: token.address.toLowerCase()
  })), [tokensResponse?.tokens, userImportedTokens]);

  // Only useful for generic ERC20 calls
  function getWrappedNativeTokenInstance() {
    return getTokenInstance(networkConfig.wethAddress);
  }
  function getNativeToken() {
    return tokens.find(t => t.address === networkConfig.eth.address);
  }
  const getToken = useCallback((address: string): GqlToken | null => {
    if (address === 'ETH') return getNativeToken();
    return tokens.find(token => token.address.toLowerCase() === address?.toLowerCase()) || null;
  }, [tokens]);
  const getTokenInstance = useCallback((address: string): Token | null => {
    const token = getToken(address);
    if (!token) return null;
    return new Token(token.chainId, token.address, token.decimals > 18 ? 18 : token.decimals, token.symbol);
  }, [getToken]);
  function getTokenInstances(addresses: string[]): Token[] {
    return addresses?.map(address => {
      const token = getToken(address);
      if (!token) return null;
      return new Token(token.chainId, token.address, token.decimals > 18 ? 18 : token.decimals, token.symbol);
    }).filter(t => t !== null) || [];
  }
  function getTradableToken(address: string): GqlToken | null {
    const token = tokens.find(token => token.address.toLowerCase() === address.toLowerCase() && token.tradeable);
    return token || null;
  }
  function getStablecoins() {
    return tokens.filter(t => t.isStableCoin);
  }
  function getStablecoinsInstances() {
    return getTokenInstances(tokens.filter(t => t.isStableCoin).map(t => t.address));
  }
  function getRequiredToken(address: string): GqlToken {
    const token = getToken(address);
    if (!token) {
      return {
        __typename: 'GqlToken',
        symbol: 'NONE',
        name: 'Missing',
        decimals: 18,
        address,
        chainId,
        tradeable: false,
        isStableCoin: true,
        isQuoteToken: false
      };
    }
    return token;
  }
  function priceFor(address: string): number {
    return getToken(address)?.currentPrice?.price || 0;
  }
  function priceForAmount(tokenAmount: TokenAmountHumanReadable): number {
    return priceFor(tokenAmount.address) * parseFloat(tokenAmount.amount);
  }
  function formattedPrice(tokenAmount: TokenAmountHumanReadable): string {
    const price = priceForAmount(tokenAmount);
    return numberFormatUSDValue(price);
  }
  function getLiquidityTrackingTokens() {
    return getTokenInstances(tokens.filter(t => t.isQuoteToken).map(t => t.address));
  }
  function getWBTCTokenInstance() {
    return getTokenInstance(networkConfig.tokens.wbtc);
  }
  let tokenAddresses = tokens.map(t => t.address);
  const tokensMapping = useMemo(() => {
    const mapping: {
      [addy: string]: Token;
    } = {};
    tokenAddresses.forEach(addy => mapping[addy] = getTokenInstance(addy));
    return mapping;
  }, [tokenAddresses, getTokenInstance]);
  return {
    tradeableTokens: tokens.filter(token => token.tradeable !== false),
    // tradeableTokens: tokens,
    tokensMapping,
    allTokens: tokens,
    tokenAddresses,
    priceFor,
    getToken,
    getTradableToken,
    getRequiredToken,
    formattedPrice,
    priceForAmount,
    getTokenInstance,
    getTokenInstances,
    getLiquidityTrackingTokens,
    getNativeToken,
    getStablecoins,
    getStablecoinsInstances,
    getWrappedNativeTokenInstance,
    getWBTCTokenInstance
  };
}
export function TokensProvider(props: {
  children: ReactNode;
}) {
  const tokens = _useGetTokens();
  return <TokensContext.Provider value={tokens} data-sentry-element="unknown" data-sentry-component="TokensProvider" data-sentry-source-file="useToken.tsx">{props.children}</TokensContext.Provider>;
}
export const useGetTokens = () => useContext(TokensContext) as ReturnType<typeof _useGetTokens>;