import { NetworkConfig } from './network-config-type';

export const OptimismNetworkConfig: NetworkConfig = {
  appName: 'SuperSwap',
  chainId: 10,
  networkName: 'Optimism',
  networkShortName: 'OP',
  etherscanName: 'OP Mainnet Explorer',
  etherscanUrl: 'https://optimistic.etherscan.io',
  dexIcon: '',
  dexIconInRepo: '/logo/superswap_logo1.png',
  testnet: false,
  isV2Supported: true,
  ens: {
    hasWebNameSupport: false,
    nameServiceId: null,
  },
  iconUrl: 'https://assets.coingecko.com/coins/images/25244/standard/Optimism.png?1696524385',
  eth: {
    name: 'ETH',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    symbol: 'ETH',
    decimals: 18,
    iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
  },
  wethAddress: '0x4200000000000000000000000000000000000006',
  wethAddressFormatted: '0x4200000000000000000000000000000000000006',
  tokens: {
    wbtc: '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
    op: '0x4200000000000000000000000000000000000042',
  },
  coingecko: {
    nativeAssetId: 'ether',
    platformId: 'ethereum',
  },
  rpcUrl: 'https://mainnet.optimism.io',
  // fully synced w/ b/e but 2 of them below are listed in multiple places in contracts repo
  multicall: '0x2dc0e2aa608532da689e89e237df582b783e552c',
  contracts: {
    v2Router: '0x6D0829dABd4B41e9a999283a11DDa1516F591e86',
    v2Zap: '0xbA38040Af245ca5829B32AF4D4C9be7dB26d8c15',
    uniMulticall: '0x1F98415757620B543A52E61c46B32eB19261F984',
    v3: {
      factory: '0xe52a36Bb76e8f40e1117db5Ff14Bd1f7b058B720',
      nonFungibleManager: '0x74a52eb08d699cd8be1d42da4b241d526b8a8285',
      tickLens: '0xFb68BBfaEF679C1E653b5cE271a0A383c0df6B45',
    },
    tokenLocker: '',
    yieldBooster: '',
  },
  protocolToken: {
    address: '',
    symbol: '',
  },
  secondProtocolToken: {
    address: '',
    symbol: '',
  },
  xProtocolToken: {
    address: '',
    symbol: '',
  },
  mainPoolId: '',
  secondaryPoolId: '',

  masterChefContractAddress: '',
  defaultTokenIn: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  defaultTokenOut: '0x4200000000000000000000000000000000000042',
  farmTypeName: '',
  additionalLinks: [],
  homeText: 'SuperSwap',
  priceImpact: {
    invest: {
      noticeable: 0.005,
      high: 0.01,
    },
    trade: {
      noticeable: 0.01,
      high: 0.05,
    },
    withdraw: {
      noticeable: 0.005,
      high: 0.01,
    },
  },
  merkl: {
    isSupported: true,
    distributorAddress: '0x3Ef3D8bA38EBe18DB133cEc108f4D14CE00Dd9Ae',
    additionalRewardToken1: '0x4200000000000000000000000000000000000042',
    additionalRewardToken1Symbol: 'OP',
  },
  odos: {
    isOdosChain: true,
    referralCode: 1532712713,
    routerAddress: '0xca423977156bb05b13a2ba3b76bc5419e2fe9680',
  },
  socialLinks: {
    reddit: '',
    twitter: 'https://twitter.com/SuperSwapFi',
    telegram: 'https://t.me/BaseswapFi',
    // youtube: "https://www.youtube.com/@BaseSwap",
    medium: '',
    discord: 'https://discord.com/invite/xxxRnyjYAC',
    documents: 'https://superswap.gitbook.io/superswap',
    // linktree: "https://linktr.ee/swapmodefi",
    // warpcast: "https://warpcast.com/swapmode",
  },
  locker: {
    lockingToken: '',
  },
};
