import { NetworkConfig } from './network-config-type';

export const SoneiumNetworkConfig: NetworkConfig = {
  appName: 'SuperSwap',
  chainId: 1946,
  networkName: 'Soneium Testnet',
  networkShortName: 'Soneium Testnet',
  etherscanName: 'Minato Explorer',
  etherscanUrl: 'https://explorer-testnet.soneium.org',
  dexIcon: '',
  dexIconInRepo: '/logo/sonyswap.webp',
  testnet: true,
  isV2Supported: true,
  ens: {
    hasWebNameSupport: false,
    nameServiceId: null,
  },
  iconUrl: 'https://soneium.org/_next/static/media/symbol-full-color.b3009da0.webp',
  eth: {
    name: 'ETH',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    symbol: 'ETH',
    decimals: 18,
    iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
  },
  wethAddress: '0x4200000000000000000000000000000000000006',
  wethAddressFormatted: '0x4200000000000000000000000000000000000006',
  tokens: {
    wbtc: '',
  },
  coingecko: {
    nativeAssetId: 'ether',
    platformId: 'ethereum',
  },
  rpcUrl: 'https://rpc.minato.soneium.org/',

  multicall: '0xB687282AD4Fb8897D5Cd41f3C1A54DeB4cc88625',
  contracts: {
    v2Router: '0x74a52eb08d699CD8BE1d42dA4B241d526B8a8285',
    v2Zap: '0x8DFAf055e21B16302DBf00815e5b4d9b6042a4Df',
    // unimultical
    uniMulticall: '0x2B0A43DCcBD7d42c18F6A83F86D1a19fA58d541A',

    v3: {
      //UniswapV3Factory
      factory: '0xe52a36Bb76e8f40e1117db5Ff14Bd1f7b058B720',

      // nonFungibleManager: '0x763c2e4393Ed80480D735193D57f31692Fe4504e',
      nonFungibleManager: '0x78a087d713Be963Bf307b18F2Ff8122EF9A63ae9',
      tickLens: '0xFb68BBfaEF679C1E653b5cE271a0A383c0df6B45',
    },
    tokenLocker: '',
    yieldBooster: '',
  },
  protocolToken: {
    address: '',
    symbol: '',
  },
  xProtocolToken: {
    address: '',
    symbol: '',
  },
  mainPoolId: '',
  secondaryPoolId: '',

  masterChefContractAddress: '',
  defaultTokenIn: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  defaultTokenOut: '0xE9A198d38483aD727ABC8b0B1e16B2d338CF0391',
  farmTypeName: '',
  additionalLinks: [],
  priceImpact: {
    invest: {
      noticeable: 0.005,
      high: 0.01,
    },
    trade: {
      noticeable: 0.01,
      high: 0.05,
    },
    withdraw: {
      noticeable: 0.005,
      high: 0.01,
    },
  },
  merkl: {
    isSupported: false,
    distributorAddress: '',
    additionalRewardToken1: '',
    additionalRewardToken1Symbol: '',
  },
  odos: {
    isOdosChain: false,
    referralCode: 2005541195,
    routerAddress: '',
  },
  socialLinks: {
    reddit: 'https://www.reddit.com/r/BaseswapFi/',
    twitter: 'https://twitter.com/arbidexfi',
    telegram: 'https://t.me/BaseswapFi',
    // youtube: "https://www.youtube.com/@BaseSwap",
    medium: 'https://medium.com/@BaseSwap',
    discord: 'https://discord.gg/arbidex',
    documents: 'https://docs.soneium.org/docs/builders/overview',
    // linktree: "https://linktr.ee/swapmodefi",
    // warpcast: "https://warpcast.com/swapmode",
  },
  locker: {
    lockingToken: '',
  },
};
