import { getAddress } from 'ethers/lib/utils';
import memoize from 'lodash/memoize';
import { Address } from 'viem';
import { ZERO_ADDRESS } from './web3';
import { Maybe } from '~/types';

export enum AddressStringFormat {
  Lowercase,
  Uppercase,
  Shortened,
}

export function addressesMatch(address1: string, address2: string) {
  return address1.toLowerCase() === address2.toLowerCase();
}

export function addressShortDisplayName(address: string) {
  const formatted = getAddress(address);

  return `${formatted.slice(0, 4)}...${formatted.slice(-4)}`;
}

// returns the checksummed address if the address is valid, otherwise returns false
export const isAddress = memoize((value: any): string | false => {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
});

// returns the checksummed address if the address is valid, otherwise returns undefined
export const safeGetAddress = memoize((value: any): string | undefined => {
  try {
    let value_ = value;
    if (typeof value === 'string' && !value.startsWith('0x')) {
      value_ = `0x${value}`;
    }
    return getAddress(value_);
  } catch {
    return undefined;
  }
});

export const isSameAddress = (address1: string, address2: string) => {
  return addressesMatch(address1, address2);
};

export function addressesAreEquivalent(a: string | null | undefined, b: string | null | undefined) {
  if (!a || !b) return false;
  return a === b || a.toLowerCase() === b.toLowerCase();
}

export function asViemAddress(address: string) {
  return (address || ZERO_ADDRESS) as Address;
}

export function getChainDatabaseId(chainId: number, address: string) {
  if (!chainId || !address) return null;

  return `${chainId}-${address.toLowerCase()}`;
}

/**
 * Normalizes an address given a format
 *
 * **Note**: To get the checksum address please, use {@link getValidAddress(address, true)}
 *
 * @param address
 * @param format One of AddressStringFormat
 * @returns the normalized address
 */
export function normalizeAddress(address: string, format: AddressStringFormat): string {
  switch (format) {
    case AddressStringFormat.Lowercase:
      return address.toLowerCase();
    case AddressStringFormat.Uppercase:
      return address.toUpperCase();
    case AddressStringFormat.Shortened:
      return address.substr(0, 8);
    default:
      throw new Error(`Invalid AddressStringFormat: ${format}`);
  }
}

/**
 * Validates an address and returns the normalized address: lowercased or checksummed depending on the checksum field.
 *
 * When withChecksum === true, this method performs a checksum on the address. Please, use only for validating user input.
 *
 * When withChecksum === false, it checks: length === 42 and startsWith('0x') and returns a lowercased address.
 *
 * Usage:
 * `if(getValidAddress(address, withChecksum))`: Works because strings are truthy and null is falsy
 *
 * @param address The address to validate and normalize
 * @param withChecksum Whether to perform a checksum on the address
 * @param log If logging is enabled in case of errors
 * @returns The normalized address or false if the address is invalid
 */
export function getValidAddress(
  address: Maybe<string>,
  withChecksum = false,
  log = true,
): Nullable<string> {
  if (!address) {
    return null;
  }

  const addressWith0x = ensureLeading0x(address);

  if (withChecksum) {
    try {
      return getAddress(addressWith0x);
    } catch (error) {
      // if (log) {
      //   logger.warn('utils/addresses', 'getValidAddress', 'Invalid address at checksum', {
      //     data: address,
      //     stacktrace: new Error().stack,
      //   });
      // }

      console.log('utils/addresses', 'getValidAddress', 'Invalid address at checksum', {
        data: address,
        stacktrace: new Error().stack,
      });
      return null;
    }
  }

  if (addressWith0x.length !== 42) {
    // if (log) {
    //   logger.warn('utils/addresses', 'getValidAddress', 'Address has an invalid format', {
    //     data: address,
    //     stacktrace: new Error().stack,
    //   });
    // }

    console.log('utils/addresses', 'getValidAddress', 'Address has an invalid format', {
      data: address,
      stacktrace: new Error().stack,
    });
    return null;
  }

  return normalizeAddress(addressWith0x, AddressStringFormat.Lowercase);
}

export function areAddressesEqual(a1: Maybe<string>, a2: Maybe<string>): boolean {
  const validA1 = getValidAddress(a1);
  const validA2 = getValidAddress(a2);
  return validA1 !== null && validA2 !== null && validA1 === validA2;
}

export function ensureLeading0x(input: string): string {
  return input.startsWith('0x') ? input : `0x${input}`;
}
