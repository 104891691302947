import { NetworkConfig } from './network-config-type';

export const BaseNetworkConfig: NetworkConfig = {
  appName: 'BaseSwap',
  chainId: 8453,
  networkName: 'Base',
  networkShortName: 'Base',
  etherscanName: 'Base Scan',
  etherscanUrl: 'https://basescan.org',
  dexIcon: 'https://baseswap.fi/images/tokens/0x78a087d713Be963Bf307b18F2Ff8122EF9A63ae9.png',
  dexIconInRepo: '/logo/BSWAP.png',
  testnet: false,
  isV2Supported: true,
  ens: {
    hasWebNameSupport: false,
    nameServiceId: null,
  },
  iconUrl:
    'https://raw.githubusercontent.com/base-org/brand-kit/main/logo/symbol/Base_Symbol_Blue.png',
  eth: {
    name: 'ETH',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    symbol: 'ETH',
    decimals: 18,
    iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
  },
  wethAddress: '0x4200000000000000000000000000000000000006',
  wethAddressFormatted: '0x4200000000000000000000000000000000000006',
  tokens: {
    wbtc: '0x1a35EE4640b0A3B87705B0A4B45D227Ba60Ca2ad', // axlWBTC
  },
  coingecko: {
    nativeAssetId: 'ether',
    platformId: 'ethereum',
  },
  rpcUrl:
    'https://rpc.ankr.com/base/49e8f39a9a4ec3f43b5ae964dfd6aa83b36e19dbb270a73f9121a9e593d85ad2',

  multicall: '0x942a772191A34040121C69bE6caBFEE6312ab641',
  contracts: {
    v2Router: '0x327Df1E6de05895d2ab08513aaDD9313Fe505d86',
    v2Zap: '0xc102505248c36f933934d4B2d7579D962a342eBC',
    uniMulticall: '0x091e99cb1C49331a94dD62755D168E941AbD0693',
    v3: {
      factory: '0x38015D05f4fEC8AFe15D7cc0386a126574e8077B',
      nonFungibleManager: '0xDe151D5c92BfAA288Db4B67c21CD55d5826bCc93',
      tickLens: '0x49a3A5cf91DE1b78c43Dc1adD03E8A71f1Ea2e30',
    },
    tokenLocker: '0x4e4c89937f85bD101C7FCB273435Ed89b49ad0B0',
    yieldBooster: '0x0F5433c9f1c2E86588304eD09BC79AbEcc89e0de',
  },
  protocolToken: {
    address: '0xd5046b976188eb40f6de40fb527f89c05b323385',
    symbol: 'BSX',
  },
  secondProtocolToken: {
    address: '0x78a087d713Be963Bf307b18F2Ff8122EF9A63ae9',
    symbol: 'BSWAP',
  },
  xProtocolToken: {
    address: '0xE4750593d1fC8E74b31549212899A72162f315Fa',
    symbol: 'xBSX',
  },
  mainPoolId: '',
  secondaryPoolId: '',

  masterChefContractAddress: '',
  defaultTokenIn: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  defaultTokenOut: '0x78a087d713Be963Bf307b18F2Ff8122EF9A63ae9',
  farmTypeName: '',
  additionalLinks: [],
  priceImpact: {
    invest: {
      noticeable: 0.005,
      high: 0.01,
    },
    trade: {
      noticeable: 0.01,
      high: 0.05,
    },
    withdraw: {
      noticeable: 0.005,
      high: 0.01,
    },
  },
  merkl: {
    isSupported: true,
    distributorAddress: '0x3Ef3D8bA38EBe18DB133cEc108f4D14CE00Dd9Ae',
    // additionalRewardToken1: '0xc3De830EA07524a0761646a6a4e4be0e114a3C83',
    // additionalRewardToken1Symbol: 'UNI',
  },
  odos: {
    isOdosChain: true,
    referralCode: 1190159976,
    routerAddress: '0x19cEeAd7105607Cd444F5ad10dd51356436095a1',
  },
  socialLinks: {
    reddit: 'https://www.reddit.com/r/BaseswapFi/',
    twitter: 'https://twitter.com/BaseSwapDex',
    telegram: 'https://t.me/BaseswapFi',
    // youtube: "https://www.youtube.com/@BaseSwap",
    medium: 'https://medium.com/@BaseSwap',
    discord: 'https://discord.gg/baseswap',
    documents: 'https://docs.baseswap.fi/baseswap/',
    // linktree: "https://linktr.ee/swapmodefi",
    // warpcast: "https://warpcast.com/swapmode",
  },
  bridgeExternal1: {
    name: 'Across Protocol Bridge',
    link: 'https://app.across.to/bridge?',
  },
  perpetuals: {
    name: 'Perpetuals',
    link: 'https://perps.baseswap.fi/',
  },
  locker: {
    lockingToken: 'BSWAP',
  },
  a51: {
    dexName: 'baseswap-uniswap-v3-cloned',
    serverEndpoint: 'https://api.a51.finance',
    repoUrl: 'https://automated.baseswap.fi',
    isSupported: true,
  },
  zapApi: 'https://zap-api.kyberswap.com/base/api/v1/in/',
  zapRouterAddress: '0x0e97C887b61cCd952a53578B04763E7134429e05',
  networkZapName: 'DEX_SWAPMODEV3',
};
