'use client';
import { CHAIN_MAIN } from '~/constants';
import { ArbitrumNetworkConfig } from '../config/arbitrum-config';
import { BaseNetworkConfig } from '../config/base-config';
import { ModeNetworkConfig } from '../config/mode-config';
import { OptimismNetworkConfig } from '../config/optimism-config';
import { SoneiumNetworkConfig } from '../config/soneium-config';

const getNetworkConfig = (chainId: number) => {
  switch (chainId) {
    case 34443:
      return ModeNetworkConfig;
    case 8453:
      return BaseNetworkConfig;
    case 10:
      return OptimismNetworkConfig;
    case 42161:
      return ArbitrumNetworkConfig;
    case 1946:
      return SoneiumNetworkConfig;
    default:
      throw new Error(`Unsupported chain ID: ${chainId}`);
  }
};

function useNetworkConfig() {
  const networkConfig = getNetworkConfig(CHAIN_MAIN);
  // const [networkConfig, setNetworkConfig] = useState<NetworkConfig>(BaseNetworkConfig);
  // const chainId = useChainId();

  // useEffect(() => {
  //   if (isSupportedChain(chainId)) setNetworkConfig(AllNetworkConfigs[chainId]);
  // }, [chainId]);

  return networkConfig;
}

export default useNetworkConfig;
